<template>
  <div class="card shadow-none  ">
    <div class="card-header d-flex align-items-center justify-content-between">
      <h6>
        <span v-text="$t('dashboard.order.orderSummary')"> </span>
        <i class=" fa fa-question-circle fa-sm text-info ml-1 "
          v-b-popover.hover.bottom="$t('messages.ivaDetails')"></i>

      </h6>
      <button class="btn btn-sm btn-info" @click="fnGetOrderSummary()" :disabled="loadingSummary"
        v-if=" orderInformation.shipment_status != 'delivered' ">
        <span v-text=" $t('general.button.orderSummary') "></span>
      </button>
    </div>
    <div class="card-body p-0 ">

      <table class=" table table-bordernone table-striped">
        <tbody>
          <tr v-for=" (item, index) in  orderInformation.items " :key="index"
            class=" text-center border-bottom-primary ">
            <td class=" ">
              <div class="row">
                <div class="col-auto text-center">
                  <img v-image=" item.image_url " class=" img-cover-40 border bg-white " alt="">
                </div>
                <div class="col text-left ">
                  <p v-if="item.sku" class="mb-0 text-truncate">
                    <small class="text-muted f-w-500">
                      <span class="text-uppercase" v-text=" $t('tables.sku') +': ' "></span>
                      <span v-text="  item.sku "></span>
                    </small>
                  </p>
                  <p class="">
                    <span v-text=" item.name "> </span>
                  </p>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <p v-if="  item.variant_name && item.variant_name != 'Default Title' " class=" ">
                        <strong v-text=" $t('dashboard.products.variant') +': ' "> </strong>
                        <span v-text="item.variant_name"></span>
                      </p>
                    </div>
                    <div class="col-12 text-right">
                      <p class="text-right"
                        v-text=" item.quantity + ' X ' + fnFormatPrice(  item.price , orderInformation.currency )"
                        v-if="item.discount_percent == 0 ">
                      </p>
                      <p class="text-right" v-else>
                        <span v-text=" item.quantity + ' X '"></span>
                        <span
                          v-text=" ' ' + fnFormatPrice(  (  item.price - ( ( item.price / 100) * item.discount_percent ) ) , orderInformation.currency ) "></span>
                        <strong class="text-warning " v-text=" ' ' + item.discount_percent + '% OFF'"></strong>
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
    <div class="card-footer">
      <div class="row mb-1">
        <div class="col-6">
          <p> Subtotal: </p>
        </div>
        <div class="col-6 text-right ">
          <span v-text=" fnFormatPrice( orderInformation.totals.subtotal , orderInformation.currency ) "></span>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <p v-text=" $t('general.form.discount') + ': '"> </p>
        </div>
        <div class="col-6 text-right">
          <span v-text="'- ' +  fnFormatPrice( orderInformation.totals.discount , orderInformation.currency ) "></span>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <p v-text=" $t('general.form.shipping') + ': '"> </p>
        </div>
        <div class="col-6 text-right">
          <span v-text="fnFormatPrice( orderInformation.totals.shipping , orderInformation.currency ) "></span>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <p class="m-0" v-text=" $t('messages.paidByCustomer') + ': ' "> </p>
        </div>
        <div class="col-6 text-right">
          <p class="text-muted">
            <strong v-text=" fnFormatPrice( (orderInformation.totals.total) , orderInformation.currency ) "></strong>
          </p>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row" v-if="orderInformation.totals.shipping > 0">
        <div class="col-6">
          <p v-text=" $t('general.form.shipping') + ': '"> </p>
        </div>
        <div class="col-6 text-right">
          <span v-text=" '- ' +  fnFormatPrice(orderInformation.totals.shipping , orderInformation.currency ) "></span>
        </div>
      </div>
      <div class="row " v-for=" (charge,index) in orderInformation.charge" :key="index">
        <div class="col-6">
          <p>
            <router-link to="/dashboard/payments" v-if=" charge.name == 'ISR' && invociesList.length == 0"
              v-text="charge.name">
            </router-link>
            <span v-text="charge.name" v-else></span>
          </p>
        </div>
        <div class="col-6">
          <p class="text-right" v-text=" '- ' + fnFormatPrice( charge.charge_amount , orderInformation.currency )  "></p>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <p class="m-0" v-text=" $t('general.form.total') + ': ' "> </p>
        </div>
        <div class="col-6 text-right">
          <p class="text-success">
            <strong v-text=" fnFormatPrice( (orderInformation.totals.total - totalCharges) , orderInformation.currency ) "></strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import printJS from 'print-js';
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";

  export default {

    data() {
      return {
        loadingSummary: false,
      }
    },
    computed: {
      ...mapState('order', ['orderInformation']),
      ...mapState(['device']),
      ...mapState('payments', ['invociesList']),
      totalCharges() {
        let tempCharges = 0;
        tempCharges += this.orderInformation.totals.shipping;
        tempCharges += this.orderInformation.totals.tax;
        if (this.orderInformation.charge) {
          this.orderInformation.charge.map(charge => {
            tempCharges += Number('' + charge.charge_amount.toFixed(2));
          })
        }
        return tempCharges;
      }
    },
    methods: {
      ...mapActions('payments', ['fnApiGeInvoices']),
      async fnGetOrderSummary() {
        if (this.device) {
          DownloadFile.postMessage(JSON.stringify({
            url: window.location.origin + `/seller/orders/summary/${this.orderInformation.id}`,
            fileName: this.orderInformation.number + '-Summary.pdf',
          }));
          // PDFLink.postMessage(JSON.stringify({
          //   title: this.$t('dashboard.order.orderSummary'),
          //   url: window.location.origin + `/seller/orders/summary/${this.orderInformation.id}`,
          // }));
        } else {
          this.loadingSummary = true;
          await axios.get(`orders/summary/${this.orderInformation.id}`, {
            headers: this.headers,
            responseType: 'blob',
          }).then((response) => {
            var pdfFile = new Blob([response.data], {
              type: "application/pdf"
            });
            var pdfUrl = URL.createObjectURL(pdfFile);
            printJS(pdfUrl);

          }).catch(error => {});
          this.loadingSummary = false;
        }

      }
    },
    mounted() {
      this.fnApiGeInvoices();
    }
  }
</script>

<style>

</style>